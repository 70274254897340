import {getAdminAuthRule} from '@/api/admin'

import BasicLayout from '@/layouts/BasicLayout';
import UserLayout from '@/layouts/UserLayout';
import RouteView from '@/layouts/RouteView';

// 前端路由表
const constantRouterComponents = {
    BasicLayout: BasicLayout,
    UserLayout:UserLayout,
    RouteView:RouteView,

    //概况
    Overview : () => import('@/pages/overview/Index'),

    //企业
    //企业列表
    OrganizationIndex:() => import('@/pages/organization/organization/list/Index'),
    OrganizationList:() => import('@/pages/organization/organization/list/List'),
    //激活码列表
    ActivationCodeIndex:() => import('@/pages/organization/activation_code/list/Index'),
    ActivationCodeList:() => import('@/pages/organization/activation_code/list/List'),

    //用户
    //用户列表
    UserIndex:() => import('@/pages/user/user/list/Index'),
    UserList:() => import('@/pages/user/user/list/List'),

    //群主
    //群主列表
    GroupLeaderIndex:() => import('@/pages/group_leader/group_leader/list/Index'),
    GroupLeaderList:() => import('@/pages/group_leader/group_leader/list/List'),
    //群主申请
    GroupLeaderApply:() => import('@/pages/group_leader/group_leader_apply/List'),
    //产品列表
    ProductList:() => import('@/pages/group_leader/product_library/List'),
    //轮播列表
    CarouselList:() => import('@/pages/group_leader/carousel/List'),

    //订单
    //订单列表
    OrderIndex:() => import('@/pages/order/order/list/Index'),
    OrderList:() => import('@/pages/order/order/list/List'),

    //财务
    //订单明细
    OrderDetails:() => import('@/pages/finance/order_details/List'),
    //提现申请
    WithdrawalApplication:() => import('@/pages/finance/withdrawal_application/List'),
    //余额明细
    BalanceDetails:() => import('@/pages/finance/balance_details/List'),
    //猫币明细
    VirtualCurrencyBalanceDetails:() => import('@/pages/finance/virtual_currency_balance_details/List'),

    //产品
    //私客产品列表
    NoteIndex:() => import('@/pages/note/note/list/Index'),
    NoteList:() => import('@/pages/note/note/list/List'),
    //公库产品列表
    ShareNoteIndex:() =>  import('@/pages/note/share_note/list/Index'),
    ShareNoteList:() => import('@/pages/note/share_note/list/List'),
    //公库分类列表
    ShareCategoryIndex:() =>  import('@/pages/note/share_category/list/Index'),
    ShareCategoryList:() => import('@/pages/note/share_category/list/List'),

    //看板
    //求购看板
    CaigouIndex:() => import('@/pages/bulletinboard/caigou/list/Index'),
    CaigouList:() => import('@/pages/bulletinboard/caigou/list/List'),
    //货源看板
    HuoyuanIndex:() => import('@/pages/bulletinboard/huoyuan/list/Index'),
    HuoyuanList:() => import('@/pages/bulletinboard/huoyuan/list/List'),

    //会议
    //会议信息
    ConferenceInfo:() => import('@/pages/conference/info'),
    //会议报名
    ConferenceApply:() => import('@/pages/conference/apply'),
    //会议报名资料
    ConferenceApplyData:() => import('@/pages/conference/apply_data'),

    //消息
    //系统消息
    SystemMessage:() => import('@/pages/message/system_message'),
    //弹窗消息
    PopupMessage:() => import('@/pages/message/popup_message'),

    //助手
    //基础配置
    HelperConfig:() => import('@/pages/helper/Config'),
    //微信机器人
    HelperRobot:() => import('@/pages/helper/Robot'),
    //计划任务
    HelperCrontab:() => import('@/pages/helper/Crontab'),

    //反馈
    Feedback:() => import('@/pages/feedback/Index'),

    //版本
    //桌面版本
    DesktopVersion:() => import('@/pages/version/desktop/Index'),
    //app版本
    AppVersion:() => import('@/pages/version/app/Index'),

    //设置
    //账号设置
    AccountLayout:() => import('@/pages/set/account/Index'),
    //基本设置
    AccountBasic:() =>import('@/pages/set/account/Basic'),
    //安全设置
    AccountSecurity:() =>import('@/pages/set/account/Security'),
    //系统设置
    System:() => import('@/pages/set/system/Index'),
    //管理员列表
    AdminList:() => import('@/pages/set/admin/list/Index'),
    //角色管理
    AuthGroup:() => import('@/pages/set/admin/auth_group/Index'),
    //菜单管理
    AuthRule:() => import('@/pages/set/admin/auth_rule/Index'),
    //操作日志
    AdminLog:() => import('@/pages/set/admin/log/Index'),
}

// 根级菜单
const rootRouter = {
    path: '/',
    redirect: '/organization',
    component: 'BasicLayout',
    children: []
}

//固定404页
const notFoundRouter = {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
}

//动态生成菜单
export function generatorDynamicRouter (){
    return new Promise((resolve, reject) => {
        getAdminAuthRule().then(response => {
            const result = response.result;

            const menuNav = []
            //后端数据, 根级树数组,  根级 PID
            const childrenNav = [];
            //*数组转树形结构
            listToTree(result, childrenNav, 0);

            rootRouter.children = childrenNav;

            menuNav.push(rootRouter);
            const routers = generator(menuNav)
            routers.push(notFoundRouter);

            resolve(routers)
        }).catch(error => {
            reject(error)
        })
    })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
export const listToTree = (list, tree, pid) => {
    list.forEach(item => {
        if(item['pid'] == pid){
            const child = {
                ...item,
                children: []
            }

             // 迭代 list， 找到当前菜单相符合的所有子菜单
            listToTree(list, child.children, item.id)
            // 删掉不存在 children 值的属性
            if (child.children.length <= 0) {
                delete child.children
            }

            // 加入到树中
            tree.push(child)
        }
    })
 }

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap) => {
    return routerMap.map(item => {

        const currentRouter = {
            path: item.path,
            component: constantRouterComponents[item.component] ? constantRouterComponents[item.component] : '',
            meta: {
                title: item.title ? item.title : '',
                permission: item.permission ? item.permission : ''
            }
        }

        // 是否设置了隐藏菜单
        if (item.status === 'hidden') {
           currentRouter.hidden = true
        }

        // 重定向
        item.redirect && (currentRouter.redirect = item.redirect)

        // 是否有子菜单，并递归处理
        if (item.children && item.children.length > 0) {
            currentRouter.children = generator(item.children)
        }
        
        return currentRouter
    })
}